import React, { useEffect } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import Masonry from 'react-masonry-css'
import gsap from "gsap"
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger);

const breakpointColumnsObj = {
  default: 3,
  1100: 2,
  700: 2,
  500: 1
};

export default function Music({ data }) {

  useEffect(() => {
    const boxes = gsap.utils.toArray('.music-item');
    boxes.forEach((box, i) => {
      const anim = gsap.fromTo(box, {
          autoAlpha: 0,
          y: 50,
          stagger: 0.20,
          ease: "power1.in"
        }, {
          duration: 1,
          autoAlpha: 1,
          y: 0
      });
      ScrollTrigger.create({
        trigger: box,
        animation: anim,
        toggleActions: 'play none none none',
      });
    });
  }, []);

  return (
    <Layout>
      <Seo title="Chad Rieder | Minneapolis Music Photographer" description="Chad Rieder is a Minneapolis music photographer who loves capturing the rock show. He's available for concert photography and artist portraits nationally." />
      <div className="grid-items">
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="masonry-grid"
          columnClassName="masonry-grid-col"
        >
           <div className="masonry-item music-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1300/v1649519923/music/khruangbin-laura-lee.jpg"
              alt="Khruangbin"
              placeholder="blurred"
            />
            <span id="caption">Khruangbin</span>
          </div>
          <div className="masonry-item music-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1300/v1534680832/music/tom-petty-minneapolis.jpg"
              alt="Tom Petty"
              placeholder="blurred"
            />
            <span id="caption">Tom Petty</span>
          </div>
          <div className="masonry-item music-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/v1638030832/music/carl-broemel-my-morning-jacket.jpg"
              alt="Carl Broemel My Morning Jacket"
              placeholder="blurred"
            />
            <span id="caption">My Morning Jacket</span>
          </div>
          <div className="masonry-item music-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1300/v1534680836/music/sylvan-esso-live.jpg"
              alt="Sylvan Esso"
              placeholder="blurred"
            />
            <span id="caption">Sylvan Esso</span>
          </div>
          <div className="masonry-item music-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1300/v1649527447/music/taylor-hawkins-drums.jpg"
              alt="Taylor Hawkins Foo Fighters"
              placeholder="blurred"
            />
            <span id="caption">Taylor Hawkins - Foo Fighters</span>
          </div>
          <div className="masonry-item music-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1300/v1619984810/music/foo-fighters-portland.jpg"
              alt="Foo Fighters"
              placeholder="blurred"
            />
            <span id="caption">Dave Grohl - Foo Fighters</span>
          </div>
          <div className="masonry-item music-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1300/v1534680795/music/lucius-minneapolis.jpg"
              alt="Lucius"
              placeholder="blurred"
            />
            <span id="caption">Lucius</span>
          </div>
          <div className="masonry-item music-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1300/v1621182840/music/fka-twigs-live.jpg"
              alt="FKA Twigs"
              placeholder="blurred"
            />
            <span id="caption">FKA Twigs</span>
          </div>
          <div className="masonry-item music-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1300/v1534646185/music/greensky-bluegrass-palace-st-paul.jpg"
              alt="Greensky Bluegrass"
              placeholder="blurred"
            />
            <span id="caption">Greensky Bluegrass</span>
          </div>
          <div className="masonry-item music-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1300/v1534680792/music/kamasi-washington-live.jpg"
              alt="Kamasi Washington"
              placeholder="blurred"
            />
            <span id="caption">Kamasi Washington</span>
          </div>
          <div className="masonry-item music-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload//w_1300/v1668186503/music/tenacious-d-live.jpg"
              alt="Tenacious D"
              placeholder="blurred"
            />
            <span id="caption">Tenacious D</span>
          </div>
          <div className="masonry-item music-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1300/v1619984833/music/phantogram-live.jpg"
              alt="Phantogram"
              placeholder="blurred"
            />
            <span id="caption">Phantogram</span>
          </div>
          <div className="masonry-item music-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1300/v1620003629/music/tame-impala-innerspeaker.jpg"
              alt="Tame Impala First Avenue"
              placeholder="blurred"
            />
            <span id="caption">Tame Impala</span>
          </div>
          <div className="masonry-item music-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1300/v1619984811/music/father-john-misty-josh-tillman.jpg"
              alt="Father John Misty"
              placeholder="blurred"
            />
            <span id="caption">Father John Misty</span>
          </div>
          <div className="masonry-item music-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1300/v1621182481/music/muse-minneapolis.jpg"
              alt="MUSE"
              placeholder="blurred"
            />
            <span id="caption">MUSE</span>
          </div>
          <div className="masonry-item music-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1300/v1534680801/music/metric-emily-haines-1.jpg"
              alt="Emily Haines Metric"
              placeholder="blurred"
            />
            <span id="caption">Metric</span>
          </div>
          <div className="masonry-item music-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1300/v1534680829/music/sigur-ros-live.jpg"
              alt="Sigur Rós"
              placeholder="blurred"
            />
            <span id="caption">Sigur Rós</span>
          </div>
          <div className="masonry-item music-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1300/v1551637211/music/interpol-2019.jpg"
              alt="Interpol"
              placeholder="blurred"
            />
            <span id="caption">Interpol</span>
          </div>
          <div className="masonry-item music-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1300/v1534680784/music/jungle-band-minneapolis.jpg"
              alt="Jungle"
              placeholder="blurred"
            />
            <span id="caption">Jungle</span>
          </div>
          <div className="masonry-item music-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1300/v1534680781/music/jenny-lewis.jpg"
              alt="Jenny Lewis"
              placeholder="blurred"
            />
            <span id="caption">Jenny Lewis</span>
          </div>
          <div className="masonry-item music-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1300/v1534680793/music/kurt-vile-minneapolis-2.jpg"
              alt="Kyrt Vile"
              placeholder="blurred"
            />
            <span id="caption">Kurt Vile</span>
          </div>
          <div className="masonry-item music-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1300/v1534680847/music/ty-segall-live.jpg"
              alt="Ty Segall"
              placeholder="blurred"
            />
            <span id="caption">Ty Segall</span>
          </div>
          <div className="masonry-item music-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1300/v1619984829/music/temples-band.jpg"
              alt="Temples"
              placeholder="blurred"
            />
            <span id="caption">Temples</span>
          </div>
          <div className="masonry-item music-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1300/v1621182471/music/glenn-kotche-wilco.jpg"
              alt="Glenn Kotche Wilco drummer"
              placeholder="blurred"
            />
            <span id="caption">Wilco</span>
          </div>
          <div className="masonry-item music-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1300/v1554565975/music/robyn-singer.jpg"
              alt="Robyn"
              placeholder="blurred"
            />
            <span id="caption">Robyn</span>
          </div>
          <div className="masonry-item music-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1300/v1619984818/music/belle-and-sebastian.jpg"
              alt="Belle & Sebastian"
              placeholder="blurred"
            />
            <span id="caption">Belle & Sebastian</span>
          </div>
          <div className="masonry-item music-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1300/v1534680780/music/julien-baker-live.jpg"
              alt="Julien Baker"
              placeholder="blurred"
            />
            <span id="caption">Julien Baker</span>
          </div>
          
          <div className="masonry-item music-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1300/v1619984825/music/matt-and-kim.jpg"
              alt="Matt and Kim"
              placeholder="blurred"
            />
            <span id="caption">Matt and Kim</span>
          </div>
          <div className="masonry-item music-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1300/v1619984818/music/jason-isbell-live.jpg"
              alt="Jason Isbell"
              placeholder="blurred"
            />
            <span id="caption">Jason Isbell</span>
          </div>          
          <div className="masonry-item music-item" role="figure" aria-labelledby="caption">
            <StaticImage 
              src="https://res.cloudinary.com/chad-rieder-photography/image/upload/w_1300/v1534680754/music/billy-corgan-smashing-pumpkins.jpg"
              alt="Smashing Pumpkins"
              placeholder="blurred"
            />
            <span id="caption">Smashing Pumpkins</span>
          </div>
        </Masonry>
          
      </div>

    </Layout>
  )
}
